import React from 'react'
import StyledHero from '../components/StyledHero';
import { graphql } from 'gatsby';
import PageWrapper from '../components/Layout/PageWrapper';
import Title from '../components/Title';
import { Col, Container, Row } from "react-bootstrap";
// import Recipes from '../components/Recipes/Recipes';
import SEO from "../components/seo";


export default class AboutPage extends React.Component {
  render() {
    return (
      <PageWrapper>
        <SEO title="Over ..." />
        <StyledHero img={this.props.data.aboutBcg.childImageSharp.fluid}/>
        <Container fluid className="px-5">
          <Title title="over" subtitle="..." />
          <Row className="mx-0 my-auto d-flex justify-content-center pb-5">
            <Col sm={10} md={3}>
            <h4 className="text-uppercase pb-3">Persoonlijk</h4>
            </Col>
            <Col sm={10} md={6}>
            <p>
              Hoi en welkom op mijn blog. Ik ben Stefani Peters, 30 jaar en woon in Utrecht samen met mijn vriend en lieve katten Loetje en Fritz. 
              In het dagelijkse leven ben ik software engineer bij Finaps. Mijn grootste hobby is met eten bezig zijn en daarom ben ik ook deze blog begonnen.
              Deels omdat ik het fijn vind dat ik een soort van receptenboekje voor mezelf bijhoud en deels ter inspiratie voor anderen. 
              De meeste van de recepten die je hier vindt zijn dan ook een aantal van mijn lievelings en go to recepten.
            </p>
            </Col>
          </Row>
          <Row className="mx-0 my-auto d-flex justify-content-center pb-5">
          <Col sm={10} md={3}>
            <h4 className="text-uppercase pb-3">Blog</h4>
            </Col>
            <Col sm={10} md={6} className="p-0">
              <Container fluid className="pb-3">
                <h6>Recepten</h6>
                <p>
                  Zoals net al aangegeven zijn de meeste van mijn recepten ook mijn lievelings en go to recepten. Ook vind ik het altijd jammer dat je als je wilt bakken
                  je altijd van die grote taarten moet bakken. Veel van mijn taartrecepten zullen dan ook taartjes voor twee personen zijn. Aangezien mijn hobby koken is en
                  ik doordeweeks ook gewoon 40 uur moet werken, probeer ik een goede mix van makkelijk en snelle recepten voor doordeweeks te combineren met gerechten die wat langer duren
                  voor in het weekend. Omdat we thuis met z'n tweeën zijn, zijn veel van de recepten voor 2 personen. Je kunt natuurlijk altijd het recept verdubbelen als je meer wilt maken.
                </p>
              </Container>
              <Container fluid className="pb-3">
                <h6>Weekmenu's</h6>
                <p>
                  Toen ik ben gaan samenwonen ben ik begonnen met het maken van weekmenu's. Dit doe ik nu al een aantal jaar met plezier. 
                  Gedurende de week doe ik inspiratie op en houd dit bij in google keep. Mijn weekmenu loopt altijd van zaterdag tot en met vrijdag,
                  zodat ik op vrijdag en zaterdag boodschappen kan doen. Op vrijdag meestal naar de supermarkt en op zaterdag naar de markt op Vredenburg.
                  Op mijn blog zal ik dan ook proberen om elke vrijdag mijn weekmenu te posten ter inspiratie voor jou! 
                </p>
              </Container>
              <Container fluid className="pb-3">
                <h6>Blogs</h6>
                <p>
                  Ik heb ook andere hobby's waar ik zo af en toe iets over zal posten. Zo houd ik bijvoorbeeld ook heel erg van lezen, kleding maken of informatie opdoen.
                  Als ik iets tegenkom waarvan ik het ook waard vind om te delen, kun je dat hier vinden. Zo kunnen er bijvoorbeeld boek reviews voorbij komen,
                  maar ook simpele how-to's over bijvoorbeeld mealpreppen, kleding maken of zero waste lifestyle. Mocht je nog suggesties hebben of dingen die je graag wilt weten.
                  Let me know!
                </p>
              </Container>
            </Col>
          </Row>
          {/* <div className="about-left">
          <h4>Website</h4>
          <div>
            <p>
              Deze blog is helemaal zelf geprogrammeerd en gemaakt met Gatbsy/React met de headless CMS van Netlify. Omdat ik hier graag beter in wilde worden en
              omdat ik het ook gewoon leuk vind om te doen. De website zal altijd een work in progress zijn. Zo staan er een aantal dingen op mijn to-do lijstje
              die nog zullen worden toegevoegd aan de site:
            </p>
            <ul>
              <li key={1} >blogs</li>
              <li key={2} >recepten gesorteerd op soort in de menu balk</li>
              <li key={3} >opmerkingen sectie voor bij de recepten</li>
              <li key={5} >SEO</li>
            </ul>
            <p />
            <p>
              Ik sta natuurlijk altijd open voor suggesties van jullie. Stuur me vooral een mailtje: <a href={"mailto:info@stefanipeters.nl"}>info@stefanipeters.nl</a>.
            </p>
          </div>
          </div> */}
        </Container>
      </PageWrapper>
    )
  }
};

export const query = graphql`
{
    aboutBcg: file(relativePath: {eq: "aboutBcg.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  } 
`;
